import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "react-datepicker/dist/react-datepicker.css";

import { FaCalendarAlt } from "react-icons/fa";
import { reserveSchedule } from "../utils/service/reserveService";
import { featuredStore as stores } from "./Events";

import { emailRegex, phoneNumberRegex } from "../hooks/formatRegax";
import { openTerms } from "./Subscription";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import NotificationAlert from "./NotificationAlert";

import ReservationTimes from "./UI/ReservationTimes";
import {
  handleDateChange,
  handleStoreChange,
} from "../hooks/reservationHooks/handleReservation";
import { filteredDate } from "../hooks/reservationHooks/datePickerFilter";
import { fetchStoreHours } from "../actions/action/storeHourActions";

// todo: weho oth 관련 블락 데이터는 수동으로 관리하자

function ReservationOth() {
  // const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [store, setStore] = useState("2");
  // ! 10월 3일, 지금 store 값으로 받고 있는거 store.id 임, 이름이 아니라 id
  const [selectedService, setSelectedService] = useState("");

  const [email, setEmail] = useState("");
  const [Instagram, setInstagram] = useState("");

  const [name, setName] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState("");
  const [phone, setPhone] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const [isShowNotification, setIsShowNotification] = useState(false);

  // const isAdvertisement = false;

  const location = useLocation();

  const [request, setRequest] = useState("");
  const [reservationStatus, setReservationStatus] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const storeHours = useSelector((state) => state.store?.hours ?? []);

  const onClose = async () => {
    setIsShowNotification(false);
    setReservationStatus("");
  };

  const getReferrerInfo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      source: urlParams.get("utm_source") || "direct",
      medium: urlParams.get("utm_medium") || "",
      campaign: urlParams.get("utm_campaign") || "",
      raw_referrer: document.referrer || "direct",
      full_url: window.location.href,
      landing_page: window.location.pathname + window.location.search,
    };
  };

  const initializeGA4 = () => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-4L9JX65TFT", {
        gaOptions: {
          cookieFlags: "SameSite=None;Secure",
          storage: "none",
          storeGac: false,
        },
        gtagOptions: {
          send_page_view: false,
        },
        debug: true,
      });
      const referrerInfo = getReferrerInfo();
      ReactGA.event({
        category: "User Acquisition",
        action: "Page Load",
        label: referrerInfo.source,
        referrer_info: referrerInfo,
        timestamp: new Date().toISOString(),
      });

      console.log("Referrer information:", referrerInfo); // 디
    }
  };

  const trackButtonClick = (buttonName, additionalData = {}) => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Button Interaction",
        action: "Click",
        label: buttonName,
        ...additionalData,
      });
      console.log(`Button clicked: ${buttonName}`, additionalData); // 디버깅용
    }
  };

  const trackPageView = () => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: document.title,
        location: window.location.href,
        referrer: document.referrer || "direct",
      });
    }
  };

  const trackReservationAttempt = (
    reservationData,
    success,
    errorMessage = null
  ) => {
    if (process.env.NODE_ENV === "production") {
      const referrerInfo = getReferrerInfo();

      // 페이지 로드 시간 계산을 위한 현대적인 방법
      const getPageLoadTime = () => {
        if (window.performance && window.performance.getEntriesByType) {
          const perfEntries = window.performance.getEntriesByType("navigation");
          if (perfEntries.length > 0) {
            return Math.round(perfEntries[0].loadEventEnd);
          }
        }
        return undefined;
      };

      ReactGA.event({
        category: "Reservation",
        action: success ? "Submit Success" : "Submit Failure",
        label: reservationData.store,
        value: success ? 1 : 0,
        nonInteraction: false,
        service_type: reservationData.service,
        reservation_date: reservationData.reservation_date,
        reservation_time: reservationData.reservation_time,
        referrer: document.referrer || "direct",
        error_message: errorMessage,
        user_type: reservationData.email ? "new" : "returning",
        device_type: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
          navigator.userAgent
        )
          ? "mobile"
          : "desktop",
        page_load_time: getPageLoadTime(),
        timestamp: new Date().toISOString(), // 타임스탬프 추가
        viewport_size: `${window.innerWidth}x${window.innerHeight}`, // 뷰포트 크기 추가
        traffic_source: referrerInfo.source,
        traffic_medium: referrerInfo.medium,
        traffic_campaign: referrerInfo.campaign,
        raw_referrer: referrerInfo.raw_referrer,
        landing_page: referrerInfo.landing_page,
      });

      // 디버깅용 콘솔 로그
      console.log("Reservation attempt tracked:", {
        success,
        store: reservationData.store,
        service: reservationData.service,
        error: errorMessage,
      });
    }
  };
  const mountTime = Date.now();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // ? 시간을 00:00:00.000으로 설정하는 것. 처음 시간, 다음 분, 다음 초, 다음 밀리초..
  // ? 예를들어 얻는 값은 오늘이 2024년 10월 7일이면, today는 2024-10-07T00:00:00.000Z 값을 받음..
  // ! 오늘 이전의 값들을 설정 못하도록 하려고 만듬

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");
    // ! 문자열에서 숫자가 아닌 모든 문자 제거하는거임

    if (phoneNumber.length <= 3) {
      return phoneNumber;
      // ! 3개 이하면 그대로
    } else if (phoneNumber.length <= 6) {
      // ! 6개 이하면 - 자동 설립해주고
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  useEffect(() => {
    setStore("2"); // Auto-select West Hollywood
  }, []);

  useEffect(() => {
    // Fetch store hours when component mounts
    dispatch(fetchStoreHours());
  }, [dispatch]);

  useEffect(() => {
    initializeGA4();
    trackPageView();

    return () => {
      if (process.env.NODE_ENV === "production") {
        ReactGA.event({
          category: "User Engagement",
          action: "Leave Reservation Page",
          label: "Time Spent",
          value: Math.floor((Date.now() - mountTime) / 1000),
        });
      }
    };
  }, []);

  const filterPastTimes = (times, selectedDate) => {
    // Get current LA time using explicit conversion
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });

    const laTimeStr = formatter.format(new Date());
    const [datePart, timePart] = laTimeStr.split(", ");
    const [month, day, year] = datePart.split("/");
    const [hour, minute] = timePart.split(":");

    const now = new Date();
    now.setHours(parseInt(hour), parseInt(minute), 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const selectedDay = new Date(selectedDate);
    selectedDay.setHours(0, 0, 0, 0);

    // If selected date is in the future, return all times
    if (selectedDay > today) {
      return times;
    }

    // If selected date is today, filter out past times
    if (selectedDay.getTime() === today.getTime()) {
      return times.filter((time) => {
        const [hours, minutes] = time.split(":").map(Number);

        // Add buffer time (30 minutes)
        let bufferHour = parseInt(hour);
        let bufferMinute = parseInt(minute);

        if (bufferMinute >= 60) {
          bufferHour += 1;
          bufferMinute -= 60;
        }

        // Compare with buffer time
        if (hours < bufferHour) {
          return false;
        }
        if (hours === bufferHour && minutes <= bufferMinute) {
          return false;
        }
        return true;
      });
    }

    return times;
  };

  useEffect(() => {
    // Pre-fill email and name if user is logged in
    if (user) {
      setEmail(user.email);
      setName(user.name);
      setPhone(user.phoneNumber);
    }
  }, [user]);

  // ! store, date 바뀔떄마다 useEffect 실행. 당연히 처음 랜더링 될때도 되지.

  // const handleDateChange = (selectedDate) => {
  //   setDate(selectedDate);
  //   setTime("");
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let reservationData;
    if (!emailRegex.test(email)) {
      setReservationStatus("Please enter a valid email address");
      return;
    }

    if (!store) {
      setReservationStatus("Please select a store");
      return;
    }
    // Date validation
    if (!date) {
      setReservationStatus("Please select a date");
      return;
    }

    // Time validation
    if (!time) {
      setReservationStatus("Please select a time");
      return;
    }

    if (store === "2" && !selectedService) {
      setReservationStatus("Please select a service");
      return;
    }

    if (!Instagram || Instagram.trim().length === 0) {
      setReservationStatus("Please enter your Instagram handle");
      return;
    }

    // Name validation
    if (!name || name.trim().length === 0) {
      setReservationStatus("Please enter your name");
      return;
    }

    if (!selectedService || selectedService.trim() === "") {
      setReservationStatus("Please select a service");
      return;
    }

    if (!agreedToTerms) {
      setReservationStatus("Please agree to the terms and conditions");
      return;
    }

    if (!phoneNumberRegex.test(phone)) {
      setReservationStatus("The PhoneNumber format must be '111-111-1111' ");
      return;
    }
    setIsSubmitting(true);

    try {
      const selectedStore = stores.find((s) => s.id.toString() === store);
      // const selectedStoreEmail = stores.find((store) => store.email.toString() === selectedStore);
      // const [hours, minutes] = time.split(":").map(Number);
      // Number함수 = 숫자로 변환 . parseInt 같은거네
      // Combine date and time into a string
      const dateString = date.toISOString().split("T")[0]; // Get YYYY-MM-DD
      const reservationDateTime = `${dateString}`; // Format: YYYY-MM-DDTHH:mm:00

      reservationData = {
        email: email,
        name: name,
        phone: phone,
        store: selectedStore.name,
        service: selectedService,
        store_email: selectedStore.email,
        reservation_date: reservationDateTime,
        reservation_time: time,
        comments: request,
        agreedToTerms: agreedToTerms,
        isAdvertisement: Instagram,
      };
      // console.log("reservationData:" + reservationData);
      // action

      const response = await dispatch(reserveSchedule({ reservationData }));
      // const response = await axios.post("/api/booking", reservationData);
      if (response.status === 201 || response.status === 200) {
        trackReservationAttempt(reservationData, true);

        setReservationStatus("Reservation successful!");

        // Reset form
        if (!user) {
          setEmail("");
          setName("");
          setPhone("");
        }
        setStore("2");
        setDate(null);
        setTime("");
        setRequest("");
        setAgreedToTerms(false);
        setSubmitStatus(true);
      } else if (response.status === 400) {
        trackReservationAttempt(reservationData, false, response.data.message);
        setReservationStatus(response.data.message);

        setSubmitStatus(false);
      }
    } catch (error) {
      // console.error("Error submitting reservation:", error);
      trackReservationAttempt(
        reservationData,
        false,
        error.message || "Unknown error occurred"
      );
      setSubmitStatus(false);
      setReservationStatus("Failed to make reservation. Please try again.");
    } finally {
      setIsShowNotification(true);
      setIsSubmitting(false); // Stop loading
    }
  };

  return (
    <>
      <Helmet>
        <title>CERAGEM Reservation Page for OTH</title>
        <meta
          name="description"
          content="Book now for the ultimate wellness experience at the CERAGEM Lounge in West Hollywood. Indulge in Cutting-edge Massage and K-Beauty Med Spa."
        />
        <meta property="og:title" content="CERAGEM Reservation for OTH" />
        <meta
          property="og:description"
          content="Indulge in Cutting-edge Massage and K-Beauty Med Spa at CERAGEM Lounge."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://ceragem-membership.com/reservation/oth"
        />
      </Helmet>
      <div className="min-h-screen">
        <div className="w-full bg-white py-8 px-4 border-b">
          <div className="max-w-4xl mx-auto text-center space-y-3">
            {/* Decorative Top Element */}

            {/* Main Heading with Enhanced Typography */}
            <div className="flex justify-center gap-1 mb-4">
              <div className="w-1 h-1 rounded-full bg-red-500 animate-pulse"></div>
              <div className="w-1 h-1 rounded-full bg-red-400 animate-pulse delay-100"></div>
              <div className="w-1 h-1 rounded-full bg-red-300 animate-pulse delay-200"></div>
            </div>

            {/* Main Heading with Enhanced Typography - Full Width and Responsive */}
            <div className="relative w-full">
              <h1 className="text-xl sm:text-2xl md:text-[2rem] leading-tight font-extrabold text-gray-900 px-2 md:px-4">
                Book now for the
              </h1>
              <h2 className="text-xl sm:text-xl md:text-[1.7rem] leading-tight font-bold mb-4 px-2 md:px-4">
                <span className="text-red-500">
                  ultimate wellness experience
                </span>
              </h2>
            </div>

            {/* Subtitle with Accent Line */}
            <div className="relative">
              <p className="text-base sm:text-lg text-gray-700 font-medium px-2">
                at the{" "}
                <span className="text-red-500 font-semibold">
                  CERAGEM Lounge
                </span>{" "}
                in West Hollywood
              </p>
              <div className="h-px w-16 bg-gradient-to-r from-transparent via-red-400 to-transparent mx-auto mt-4"></div>
              <p className="text-sm text-gray-500 mt-3 font-light tracking-wide">
                Experience Cutting-edge Massage and K-Beauty Med Spa
              </p>

              {/* <p className="text-sm text-gray-500 mt-3 font-light tracking-wide">
              At CERAGEM West Hollywood, 8951 Santa Monica Blvd, West Hollywood,
              CA 90069
            </p> */}
            </div>
          </div>
        </div>

        <div className="sm:h-auto mx-auto bg-white rounded-lg shadow-md p-6">
          <h1 className="text-2xl font-bold text-black">Reserve your visit</h1>
          <p className="text-sm text-gray-500 font-light tracking-wide">
            CERAGEM West Hollywood, 8951 Santa Monica Blvd, West Hollywood, CA
            90069
          </p>
          <form onSubmit={handleSubmit} className="space-y-4 mt-11 mb-6">
            {!user && (
              <>
                <div>
                  <label
                    htmlFor="Instagram"
                    className="block text-sm font-medium text-red-700"
                  >
                    Instagram
                  </label>
                  <input
                    type="text"
                    id="Instagram"
                    value={Instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                    required
                    className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-red-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                  />
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-red-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-red-700"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={(e) =>
                      setPhone(formatPhoneNumber(e.target.value))
                    }
                    placeholder="(123) 456-7890"
                    required
                    className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                  />
                </div>
              </>
            )}
            <div>
              <label
                htmlFor="store"
                className="block text-sm font-medium text-red-700"
              >
                Select Store
              </label>
              <select
                id="store"
                value={store}
                //
                onChange={(e) => {
                  handleStoreChange({
                    e,
                    setStore,
                    setSelectedService,
                    setDate,
                    setTime,
                    date,
                  });
                }}
                required
                className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none appearance-none"
              >
                <option value="2">WEST HOLLYWOOD</option>
              </select>
            </div>
            <div>
              <div>
                <label className="block text-sm font-medium text-red-700 mb-2">
                  Select Service
                </label>
                <div className="flex flex-col space-y-3 mt-3">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      value="OTH-massage-and-beauty"
                      checked={selectedService === "OTH-massage-and-beauty"}
                      onChange={() =>
                        setSelectedService("OTH-massage-and-beauty")
                      }
                      className="form-radio h-5 w-5 text-red-600"
                      required
                    />
                    <span className="text-gray-700">
                      OTH Massage and Beauty
                    </span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      value="OTH-massage"
                      checked={selectedService === "OTH-massage"}
                      onChange={() => setSelectedService("OTH-massage")}
                      className="form-radio h-5 w-5 text-red-600"
                    />
                    <span className="text-gray-700">OTH Massage</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      value="OTH-beauty"
                      checked={selectedService === "OTH-beauty"}
                      onChange={() => setSelectedService("OTH-beauty")}
                      className="form-radio h-5 w-5 text-red-600"
                    />
                    <span className="text-gray-700">OTH Beauty</span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-red-700"
              >
                Select Date
              </label>
              <div className="relative">
                <DatePicker
                  selected={date}
                  onChange={(e) => {
                    handleDateChange({
                      selectedDate: e,
                      setDate,
                      setTime,
                      setSelectedService,
                      selectedService,
                      store,
                    });
                  }}
                  minDate={today}
                  filterDate={(date) => filteredDate(date, storeHours, store)}
                  dateFormat="MMMM d, yyyy"
                  className="mt-1 block w-full py-3 pl-3 pr-10 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                  wrapperClassName="w-full"
                  required
                />
                <FaCalendarAlt className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            <div>
              <label
                htmlFor="time"
                className="block text-sm font-medium text-red-700"
              >
                Select Time
              </label>
              <ReservationTimes
                store={store}
                date={date}
                selectedService={selectedService}
                onTimeSelect={(value) => setTime(value)}
              />
            </div>
            <div>
              <label
                htmlFor="request"
                className="block text-sm font-medium text-red-700"
              >
                Special Requests or Comments
              </label>
              <textarea
                id="request"
                value={request}
                onChange={(e) => setRequest(e.target.value)}
                placeholder="Any special requests or comments for your reservation?"
                rows="3"
                className="mt-1 p-3 block w-full rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
              />
            </div>
            <div className="flex items-start space-x-2">
              <div className="flex items-center h-5">
                <input
                  id="terms"
                  type="checkbox"
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                  required
                  className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                />
              </div>
              <div className="ml-2 text-sm">
                <label htmlFor="terms" className="font-medium text-gray-700">
                  I have read and agree to the{" "}
                  <button
                    type="button"
                    onClick={openTerms}
                    className="text-red-600 hover:text-red-500 underline"
                  >
                    terms and conditions
                  </button>
                </label>

                {/* <p className="text-gray-500">
                By checking this box, you agree to our Terms of Service and
                Privacy Policy
              </p> */}
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              onClick={() => trackButtonClick("Submit Reservation")}
              className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300 ease-in-out disabled:opacity-75 disabled:cursor-not-allowed relative h-[42px]"
            >
              <div className="absolute inset-0 flex items-center justify-center">
                {isSubmitting ? (
                  <div className="flex gap-1">
                    <div className="w-1.5 h-1.5 rounded-full bg-white animate-bounce"></div>
                    <div className="w-1.5 h-1.5 rounded-full bg-white animate-bounce [animation-delay:0.2s]"></div>
                    <div className="w-1.5 h-1.5 rounded-full bg-white animate-bounce [animation-delay:0.4s]"></div>
                  </div>
                ) : (
                  "Confirm Reservation"
                )}
              </div>
            </button>
          </form>
          {reservationStatus && (
            <p
              className={`mt-4 ${
                submitStatus ? "text-green-600" : "text-red-600"
              } font-semibold text-center`}
            >
              {reservationStatus}
            </p>
          )}
          <button
            onClick={() => navigate("/home")}
            className="mt-4 text-black hover:text-slate-600 underline"
          >
            Go to MainPage
          </button>
        </div>
        <NotificationAlert
          show={isShowNotification}
          message={reservationStatus}
          type={submitStatus === true ? "success" : "error"}
          onClose={onClose}
        />
      </div>
    </>
  );
}

export default ReservationOth;
