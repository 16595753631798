import api from "../../utils/config/api";
import { updateUser } from "../type/types";

export const subscribe = (userData) => async (dispatch) => {
    try {
      const res = await api.post("/subscription", userData);
  
      if (res.data.type === "SUBSCRIBE_SUCCESS") {
        // Update user state with Square IDs from the response
        dispatch({
          type: "USER_ALL_INFO_UPDATE",
          payload: {
            square_invoice_id: res.data.user.square_invoice_id,
            square_customer_id: res.data.user.square_customer_id,
            square_order_id: res.data.user.square_order_id,
          },
        });
  
        return { type: res.data.type, msg: res.data.msg };
      }
  
      return { type: res.data.type, msg: res.data.msg };
    } catch (error) {
      return {
        type: error.response?.data?.type || "SUBSCRIBE_ERROR",
        msg: error.response?.data?.msg || "Subscription request failed",
      };
    }
  };
  
  export const subscribe_check = (user) => async (dispatch) => {
    try {
      if (!user?.email) {
        // console.error("No user email found for subscription check");
        return;
      }
  
      const res = await api.post("/subscription/check", { email: user.email });
  
      if (res.status === 200) {
        dispatch({
          type: "USER_ALL_INFO_UPDATE",
          payload: res.data.user,
        });
      }
    } catch (error) {
      console.error("Subscription check error:", error);
      // return false;
    }
  };



  
  export const beforePaymentLogic = (userData) => async () => {
    try {
      const res = await api.post("/subscription/create-customer", userData);
  
      if (res.status === 200) {
        // todo: 스퀘어 내에서 고객 체크 및 생성, 주문 생성까지 완료.
        // todo: 반환할게 있는가 ? 그냥 데이터 업데이트나 할까
      }
  
      return { message: "Customer Register Success", status: res.status };
    } catch (error) {
      console.log('create-customer error:' +error);
      console.log('create-customer error:' +error.message);
      console.log('create-customer error:' +error.response.message);
      console.log('create-customer error:' +JSON.stringify(error.response.data.message));
  
      console.log('create-customer error:' + JSON.stringify(error));
      return {
        message: error?.response?.data?.message,
        status: error?.response?.status,
      };
    }
  };



export const cancelSubscription = (userCredentials) => async (dispatch) => {
    try {
      const response = await api.post("/subscription/cancel", userCredentials);
  
      // console.log('구독 취소 결과:'+ response.data);
      if (response.status === 200) {
        dispatch(updateUser(response.data.userData));
        return { success: true, msg: response.data.msg };
      } else {
        dispatch(updateUser(response.data.userData));
        return { success: false, msg: response.data.msg };
      }
      // ! 아니네 ㅋㅋ status 받는 곳 어디던, 200이 아닌 400 받으면 error로 빠지는거 같음.
    } catch (error) {
      // console.log('구독 취소 액션 에러:');
      // console.log('구독 취소 액션 에러:'+JSON.stringify(error.response));
      // console.log('값 전송');
      // console.log(error.response.data.userData);
      dispatch(updateUser(error.response.data.userData));
      return error.response.data;
    }
  };