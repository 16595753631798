export const ErrorTypes = {
  
    USER_NOT_FOUND: "USER_NOT_FOUND",
    INVALID_INPUT: "INVALID_INPUT",
    ALREADY_SUBSCRIBED: "ALREADY_SUBSCRIBED",
    SUBSCRIPTION_CREATION_FAILED: "SUBSCRIPTION_CREATION_FAILED",
    SQUARE_API_ERROR: "SQUARE_API_ERROR",
    PAYMENT_FAILED: "PAYMENT_FAILED",
    CARD_ERROR: "CARD_ERROR",
    VERIFICATION_FAILED: "VERIFICATION_FAILED",
    CUSTOMER_CREATION_FAILED: "CUSTOMER_CREATION_FAILED",
    SERVER_ERROR: "SERVER_ERROR",
    AUTH_ERROR: "AUTH_ERROR",
    LOGIN_FAILED: "LOGIN_FAILED",
    REGISTER_FAILED: "REGISTER_FAILED",
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
    NETWORK_ERROR: "NETWORK_ERROR"
  };