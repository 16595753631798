import { ErrorTypes } from "./errorTypes";
import { ErrorMessages } from "./errorMessages";
// errorHandler.js
export class APIError extends Error {
  constructor(type, message, details = null) {
    super(message || ErrorMessages[type] || "An error occurred");
    this.type = type;
    this.details = details;
  }
}

export const handleSquareError = (error) => {
  if (error.result?.errors?.length > 0) {
    const squareError = error.result.errors[0];

    // Map Square error codes to our error types
    const errorTypeMap = {
      CARD_DECLINED: ErrorTypes.CARD_ERROR,
      VERIFY_CVV_FAILURE: ErrorTypes.CARD_ERROR,
      VERIFY_AVS_FAILURE: ErrorTypes.VERIFICATION_FAILED,
      INVALID_EXPIRATION: ErrorTypes.CARD_ERROR,
      INVALID_CARD: ErrorTypes.CARD_ERROR,
      GENERIC_DECLINE: ErrorTypes.PAYMENT_FAILED,
      INVALID_CUSTOMER: ErrorTypes.CUSTOMER_CREATION_FAILED,
    };

    return new APIError(
      errorTypeMap[squareError.code] || ErrorTypes.SQUARE_API_ERROR,
      squareError.detail,
      { code: squareError.code }
    );
  }

  return new APIError(ErrorTypes.SQUARE_API_ERROR, error.message);
};

export const handleAPIError = (error) => {
  if (error.response?.data?.message) {
    return new APIError(
      error.response.data.type || ErrorTypes.SERVER_ERROR,
      error.response.data.message
    );
  }
  // 백엔드 메시지 없을 경우 기본 메시지
  return new APIError(
    ErrorTypes.SERVER_ERROR,
    ErrorMessages[ErrorTypes.SERVER_ERROR]
  );
};
