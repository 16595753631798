import api from "../../utils/config/api";


// () => {} 이렇게만 하면 비동기 처리 안돼서 promise 에러뜸, api 요청 못한다는거임

// todo: 고객 생성, 카드 주문 생성, 카드 결제, 구독 주문 생성, 구독 생성

export const payment = (paymentData) => async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user) {
        throw new Error("User not authenticated");
      }
  
      const res = await api.post("/payment/process", paymentData);
  
      if (res.status === 200 && res.data.success) {
        // Update user information after successful payment
        dispatch({
          type: "USER_ALL_INFO_UPDATE",
          payload: {
            subscription: true,
            subscription_day: new Date().toISOString(),
            tier: "Wellness",
            point: (user.point || 0) + 200,
            activities: [
              ...(user.activities || []),
              {
                type: "subscription",
                details: "Membership subscription activated",
                date: new Date().toISOString(),
              },
            ],
            square_subscribe_canceledDate: null, // Reset canceled date if it exists
          },
        });
  
        return {
          type: "PAYMENT_SUCCESS",
          msg: "Payment processed successfully",
        };
      }
    } catch (error) {
      // console.error("Payment error:", error);
  
      // Dispatch error to update state
      dispatch({
        type: "LOGIN_FAIL", // Using existing error handling from your reducer
        payload:
          error.response?.data?.error?.message ||
          error.message ||
          "Payment processing failed",
      });
  
      return {
        type: "PAYMENT_ERROR",
        msg:
          error.response?.data?.error?.message ||
          error.message ||
          "Payment processing failed",
      };
    }
  };