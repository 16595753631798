import { ErrorTypes } from "./errorTypes";

// errorMessages.js
export const ErrorMessages = {
    [ErrorTypes.USER_NOT_FOUND]: "User account not found. Please sign in again.",
    [ErrorTypes.INVALID_INPUT]: "Please check your input and try again.",
    [ErrorTypes.ALREADY_SUBSCRIBED]: "You already have an active subscription.",
    [ErrorTypes.SUBSCRIPTION_CREATION_FAILED]: "Unable to create subscription. Please try again.",
    [ErrorTypes.SQUARE_API_ERROR]: "Payment service temporarily unavailable. Please try again later.",
    [ErrorTypes.PAYMENT_FAILED]: "Payment failed. Please check your card details.",
    [ErrorTypes.CARD_ERROR]: "Invalid card information. Please check and try again.",
    [ErrorTypes.VERIFICATION_FAILED]: "Card verification failed. Please try a different card.",
    [ErrorTypes.CUSTOMER_CREATION_FAILED]: "Unable to create customer profile. Please try again.",
    [ErrorTypes.SERVER_ERROR]: "An unexpected error occurred. Please try again later.",
    [ErrorTypes.AUTH_ERROR]: "Authentication failed. Please try again.",
    [ErrorTypes.LOGIN_FAILED]: "Invalid credentials. Please check and try again.",
    [ErrorTypes.REGISTER_FAILED]: "Registration failed. Please try again.",
    [ErrorTypes.TOKEN_EXPIRED]: "Session expired. Please login again.",
    [ErrorTypes.NETWORK_ERROR]: "Network connection error. Please check your connection."
  };