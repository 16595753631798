// payTest.jsx
import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "./UI/Card.jsx";
import { useSelector, useDispatch } from "react-redux";
import { payment } from "../actions/action/paymentActions.js";
import { handleSquareError } from "../utils/error/errorHandler.js";
import { APIError } from "../utils/error/errorHandler.js";
import { ErrorTypes } from "../utils/error/errorTypes.js";
import { useNavigate } from "react-router-dom";
import NotificationAlert from "./NotificationAlert.jsx";

const Button = ({ className, disabled, children, ...props }) => (
  <button
    className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 
    disabled:bg-blue-300 disabled:cursor-not-allowed transition-colors
    ${className}`}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

const SquarePaymentForm = () => {
  const [card, setCard] = useState(null);
  const [payments, setPayments] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [saveCardConsent, setSaveCardConsent] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "success",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  const [billingAddress, setBillingAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "US",
  });

  // Validation functions
  const validateState = (state) => {
    const stateRegex = /^[A-Z]{2}$/;
    return stateRegex.test(state);
  };

  const validatePostalCode = (postalCode) => {
    const postalCodeRegex = /^\d{5}$/;
    return postalCodeRegex.test(postalCode);
  };

  const validateForm = () => {
    if (!saveCardConsent) {
      setError(
        "Please agree to save your card information for recurring payments"
      );
      return false;
    }

    // Check for required fields
    if (!billingAddress.addressLine1) {
      setError("Street address is required");
      return false;
    }
    if (!billingAddress.city) {
      setError("City is required");
      return false;
    }

    // Validate state format
    if (!billingAddress.state) {
      setError("State is required");
      return false;
    }
    if (!validateState(billingAddress.state)) {
      setError("State must be a 2-letter code (e.g., NY, CA)");
      return false;
    }

    // Validate postal code format
    if (!billingAddress.postalCode) {
      setError("ZIP code is required");
      return false;
    }
    if (!validatePostalCode(billingAddress.postalCode)) {
      setError("ZIP code must be 5 digits");
      return false;
    }

    return true;
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;

    // Auto-format state to uppercase
    if (name === "state") {
      setBillingAddress((prev) => ({
        ...prev,
        [name]: value.toUpperCase(),
      }));
    }
    // Only allow numbers in postal code
    else if (name === "postalCode") {
      const numbersOnly = value.replace(/[^\d]/g, "").slice(0, 5);
      setBillingAddress((prev) => ({
        ...prev,
        [name]: numbersOnly,
      }));
    } else {
      setBillingAddress((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    let mounted = true;

    const initializeSquarePayment = async () => {
      // console.log(process.env.REACT_APP_SQUARE_APP_ID);
      // Check if environment variables are properly set
      if (
        !process.env.REACT_APP_SQUARE_APP_ID ||
        !process.env.REACT_APP_SQUARE_LOCATION_ID
      ) {
        setError("Square configuration is missing. Please Contact to Admin");
        console.error("Missing Square credentials. Please Contact to Admin");
        return;
      }

      // Wait for Square.js to load
      if (!window.Square) {
        // console.log("Waiting for Square.js to load...");
        setTimeout(initializeSquarePayment, 100);
        return;
      }

      try {
        // console.log("Initializing Square payments with:", { appId, locationId });
        const paymentsInstance = window.Square.payments(
          process.env.REACT_APP_SQUARE_APP_ID,
          process.env.REACT_APP_SQUARE_LOCATION_ID
        );
        // console.log("Payments instance created:", paymentsInstance);

        const cardInstance = await paymentsInstance.card({});
        // console.log("Card instance created");

        await cardInstance.attach("#card-container");
        // console.log("Card attached to container");

        if (mounted) {
          setPayments(paymentsInstance);
          setCard(cardInstance);
          setError("");
        }
      } catch (e) {
        console.error("Square initialization error:", e);
        setError("Failed to initialize payment system");
      }
    };

    initializeSquarePayment();
    return () => {
      mounted = false;
      // Cleanup card instance if it exists
      if (card) {
        card.destroy().catch(console.error);
      }
    };
  }, [
    process.env.REACT_APP_SQUARE_APP_ID,
    process.env.REACT_APP_SQUARE_LOCATION_ID,
  ]);

  const verifyBuyer = async (payments, token) => {
    // ! 실제로는 0으로 찍어놓고, 구독 할 때 아이템 바로 저장되도록 하자.
    // ! 0찍으면 오류날거 같으니까, 0.01로 해놓고
    // ! 실제 백엔드에서는 199.99 결제하는거로 하자

    const nameParts = user.name ? user.name.split(" ") : ["", ""];
    const firstName = nameParts[0];
    const lastName =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";

    const verificationDetails = {
      amount: "0.01",
      billingContact: {
        givenName: firstName,
        familyName: lastName,
        email: user.email,
        phone: user.phoneNumber,
        addressLines: [
          billingAddress.addressLine1,
          billingAddress.addressLine2,
        ].filter(Boolean),
        locality: billingAddress.city,
        administrativeDistrictLevel1: billingAddress.state,
        // todo: we have to get city, addressLines, state from front
        postalCode: billingAddress.postalCode,
        countryCode: "US",
      },
      currencyCode: "USD",
      intent: "CHARGE",
    };

    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails
    );
    return verificationResults.token;
  };

  const tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    }
    throw new Error(`Tokenization failed with status: ${tokenResult.status}`);
  };

  const handlePaymentError = (error) => {
    if (error.response?.data) {
      return new APIError(
        error.response.data.type,
        error.response.data.msg,
        error.response.data.details
      );
    }
    return handleSquareError(error);
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (!card || !payments) {
      setError("Payment system not initialized");
      return;
    }

    if (!isAuthenticated || !user) {
      setError("Please log in to continue");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const token = await tokenize(card);
      const verificationToken = await verifyBuyer(payments, token);

      // todo: 구독은 무조건 로그인 해야되잖아. 그러면 구독 할 때 성공하면 DB에다가 id 넣고
      const paymentResult = await dispatch(
        payment({
          email: user.email,
          firstName: "",
          lastName: "",
          phoneNumber: "",
          birthDay: "",
          sourceId: token,
          verificationToken,
          locationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
          idempotencyKey: window.crypto.randomUUID(),
          customerId: "",
          addressInfo: billingAddress,
          saveCardConsent,
        })
      );

      if (paymentResult.type === "PAYMENT_SUCCESS") {
        setPaymentStatus("success");
        // Optional: Clear the card form
        await card.clear();
        setNotification({
          show: true,
          type: "success",
          message:
            "Payment successful! You are now subscribed to our membership plan.",
          type: "success",
        });
        // Optional: Redirect after successful payment
        // setTimeout(() => {
        //   navigate("/home"); // or wherever you want to redirect
        // }, 3000);
      } else {
        throw new APIError(ErrorTypes.PAYMENT_FAILED, paymentResult.msg);
      }
    } catch (error) {
      console.error("Payment submission error:", error);
      setPaymentStatus("error");
      const processedError = handlePaymentError(error);
      setNotification({
        show: true,
        type: "error",
        message:
        processedError.message,
      });
      
      setError(processedError.message || "An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };
  // todo: 여기다가 나중에 카드 저장해서, 정기 결제에 동의한다는 체크박스 만들어야함.
  return (
    <>
      <NotificationAlert
        show={notification.show}
        message={notification.message}
        type={notification.type}
        onClose={() => setNotification((prev) => ({ ...prev, show: false }))}
      />

      <Card className="w-full max-w-md mx-auto bg-white shadow-lg rounded-xl">
        <CardHeader className="border-b border-gray-100 bg-gray-50/50">
          <CardTitle className="text-xl font-semibold text-gray-800">
            Membership Payment - $200.00
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          {error && (
            <div className="p-4 mb-6 text-red-700 bg-red-50 rounded-lg border border-red-200 text-sm">
              {error}
            </div>
          )}

          <form id="payment-form" onSubmit={handlePaymentSubmit}>
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="addressLine1"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Street Address <span className="text-red-500">*</span>
                </label>
                <input
                  id="addressLine1"
                  name="addressLine1"
                  value={billingAddress.addressLine1}
                  onChange={handleAddressChange}
                  placeholder="Street address"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-200"
                />
              </div>

              <div>
                <label
                  htmlFor="addressLine2"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Apartment, suite, etc.
                </label>
                <input
                  id="addressLine2"
                  name="addressLine2"
                  value={billingAddress.addressLine2}
                  onChange={handleAddressChange}
                  placeholder="Apartment, suite, unit, etc. (optional)"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-200"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    City <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="city"
                    name="city"
                    value={billingAddress.city}
                    onChange={handleAddressChange}
                    placeholder="City"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-200"
                  />
                </div>

                <div>
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    State <span className="text-red-500">*</span>
                    <span className="ml-1 text-gray-400 font-normal">
                      (e.g., CA)
                    </span>
                  </label>
                  <input
                    id="state"
                    name="state"
                    value={billingAddress.state}
                    onChange={handleAddressChange}
                    placeholder="NY"
                    required
                    maxLength="2"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-200 uppercase"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="postalCode"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  ZIP Code <span className="text-red-500">*</span>
                </label>
                <input
                  id="postalCode"
                  name="postalCode"
                  value={billingAddress.postalCode}
                  onChange={handleAddressChange}
                  placeholder="12345"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Card Information <span className="text-red-500">*</span>
                </label>
                <div
                  id="card-container"
                  className="p-4 border border-gray-300 rounded-lg shadow-sm bg-white"
                />
              </div>

              <div className="flex items-start mb-6">
                <div className="flex items-center h-5">
                  <input
                    id="saveCard"
                    name="saveCard"
                    type="checkbox"
                    checked={saveCardConsent}
                    onChange={(e) => setSaveCardConsent(e.target.checked)}
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                    required
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="saveCard"
                    className="font-medium text-gray-700"
                  >
                    Save card information
                  </label>
                  <p className="text-gray-500 text-xs mt-1">
                    I agree to save my card information for recurring monthly
                    payments. This subscription will automatically renew each
                    month until cancelled.
                  </p>
                </div>
              </div>

              {/* Add this right after the checkbox if saveCardConsent is false */}
              {!saveCardConsent && (
                <div className="p-4 mb-6 text-amber-700 bg-amber-50 rounded-lg border border-amber-200 text-sm">
                  <div className="flex items-center">
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>
                      Card information is required for monthly subscription
                      payments
                    </span>
                  </div>
                </div>
              )}

              <Button
                type="submit"
                disabled={isLoading || !card}
                className="w-full py-3 text-base font-medium shadow-sm"
              >
                {isLoading ? (
                  <span className="flex items-center justify-center">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing...
                  </span>
                ) : (
                  "Pay $200.00"
                )}
              </Button>
            </div>
          </form>

          {paymentStatus && !error && (
            <div
              className={`mt-6 p-4 rounded-lg border ${
                paymentStatus === "success"
                  ? "bg-green-50 text-green-700 border-green-200"
                  : "bg-red-50 text-red-700 border-red-200"
              }`}
            >
              <div className="flex items-center">
                {paymentStatus === "success" ? (
                  <svg
                    className="h-5 w-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="h-5 w-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
                <p className="font-medium">
                  {paymentStatus === "success"
                    ? "Payment successful! You are now subscribed."
                    : "Payment failed. Please try again."}
                </p>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SquarePaymentForm;
