import axios from "axios";

const api = axios.create({
  baseURL: "/api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});


export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};



api.interceptors.response.use(
  (response) => {
    // 새로운 access token이 있다면 처리
    const newAccessToken = response.headers["new-access-token"];
    if (newAccessToken) {
      setAuthToken(newAccessToken);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const checkBooking = async (bookingId) => {
  try {
    console.log("bookingId:" + bookingId);
    const response = await api.get(`/booking/${bookingId}`);
    if (response.status === 200) {
      return {
        reservation_data: response.data,
        message: response?.data?.message,
        isSearched: true,
      };
    } else {
      return {
        reservation_data: response.data,
        message: response.data.message,
        isSearched: false,
      };
    }
  } catch (error) {
    console.log("checkbooking api 프론트엔드 util 에러" + error);
    return { message: error.response.data.message, isSearched: false };
  }
};

export const deleteBooking = async (bookingId) => {
  try {
    const response = await api.delete(
      `/booking/cancel-reservation/${bookingId}`
    );

    if (response.status === 200) {
      // todo: modal 보여줘서 삭제 완료됐다고 보여주기 해야되는데 일단
      return { isDeleted: true, message: response.data.message };
    } else {
      // todo: modal 에서 삭제 오류 생겼다고 알려주기
      return { isDeleted: false, message: response.data.message };
    }
  } catch (error) {
    // todo: modal 보여줘서 삭제 오류생겼따고 알려주기
    return { isDeleted: false, message: error.response.data.message };
  }
};

export default api;
